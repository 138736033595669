import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { SEO } from "~/components/SEO";
import { useStringsContext } from "~/contexts/strings-context";
import { PageProps } from "~/types/global";
import MaintainSVG from "~/assets/svg/maintainSvg";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            varqaschool
          }
    }
`;

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 2rem;
`;
const Text = styled.h1`
  font-size: 2.6rem;
  color: whitesmoke;
  font-family: "Cinzel";
  text-align: center;
  margin-bottom: 2rem;
`;

const AcademicsPage: React.FunctionComponent<PageProps> = (props) => {
  const strings = useStringsContext();
  return (
    <>
      <SEO
        lang={props.pageContext.language}
        title="Varqa School - Academics"
        description="Varqa School &amp; Junior College is one of the most prestigious schools in Ahmednagar District, having been around for more than 40 years. The school is in an area that is rich in greenery and is surrounded by an extremely healthy and open environment. Our school is also partnered with Cambridge University to provide our students with more rich content, and the school is committed to maintaining highly-trained and innovative faculty."
        keywords={["varqa", "school", "varqa school", "varka", "vaka", "varkha", "varqa school", "college", "ahmenagar", "ahmednagar", "maharashtra", "best school", "schools near me", "schol", "schoole", "schools", "varqa", "best", "top schools", "cbse", "cbse school", "state board", "state", "english medium", "school medium", "english school", "english", "ramin", "ruhiya", "saba", "sir", "madam", "ahmednagr best school", "nagar best school", "nagar", "school in nagar", "nagar school"]}
        url={props.location.pathname}
      />
      <Container>
        <MaintainSVG/>
        <Text>Varqa School Website under development.</Text>
      </Container>
      

    </>
  )
}

export default AcademicsPage
